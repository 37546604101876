import React from 'react';
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const CustomMap = () => {

  const container = {
    height: '100vh',
    width: '100%'
  };
  const position = {
    lat: 35.4326361,
    lng: 139.6149289,
  };
  const zoom = 17;

  return (
    <div>
      <div className="map-container" >
        <LoadScript googleMapsApiKey = {process.env.REACT_APP_GOOGLEMAPS_API_KEY} >
          <GoogleMap
            mapContainerStyle = {container}
            center = {position}
            zoom = {zoom}>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default CustomMap;
